app-layout, app-unauthorized-layout, app-shared-profile-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mat-sidenav-container {
    background: white;
    flex: 1;

    mat-sidenav {
      width: 30rem;

      .sidebar-options {
        padding: 2rem 0.8rem 7.5rem 0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .sidebar-header {
          width: 100%;
          display: flex;
          align-items: center;

          button {
            width: 3rem;
            height: 3rem;
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
            color: black;
            margin: 0 1.2rem 0 0;
            &:hover{
              i{
                color: white;
              }
            }
          }

          .logo {
            img {
              width: 3.3rem;
              height: auto;
              margin: 0 0.8rem 0 0;
            }

            span {
              font-size: 1.2rem;
              line-height: 1.6rem;
              font-weight: 600;
              color: black;
            }
          }
        }

        .sidebar-menu {
          width: 100%;
          margin: 2rem 0 0 0;

          li {
            height: 4rem;
            margin: 0 0 0.4rem 0;
            width: 100%;
            padding: 0 1rem;
            display: flex;
            align-items: center;
            color: $gray600;
            border-radius: 0.6rem;
            cursor: pointer;

            &.active {
              background: $gray100;

              i {
                color: $gray500;
              }
            }

            i {
              font-size: 2.3rem;
              line-height: 2.3rem;
              color: $gray400;
              margin: 0 1.6rem 0 0;
            }
            a {
              color: inherit;
              font-size: inherit;
              display: inline-block;
              width: 100%;
            };
          }
        }

        .sidebar-footer {
          margin: auto 0 0 0;
          width: 100%;
          box-sizing: border-box;
          padding: 0 1rem;

          button {
            width: 100%;
            color: white;
            span {
              margin-left: 1.4rem;
            }
            span, i{
              color: inherit;
            }
          }
        }
      }
    }

    mat-sidenav-content {
      &.mat-drawer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      router-outlet ~ *:not(app-footer) {
        width: 100%;
        flex: 1;
      }
    }
  }
}

.btn-menu-toggle {
  border: none;
  background: none;
  padding: 0;
  margin-right: 1.8rem;

  @include media(">=desktop") {
    display: none;
  }

  i {
    color: white;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .navigation {
    background: $primary;
    padding: 2rem 0 0 0;

    @include media(">=tablet") {
        padding: 4rem 0;
    }

    @include media(">=desktop") {
      padding: 4rem 0 0 0;
    }

    .container {
      position: relative;
      max-width: 72rem;
      display: flex;
      align-items: center;
      padding: 0 2rem;

      .back-button {
        display: flex;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;

        i {
          color: white;
          font-size: 2.4rem;
          line-height: 2.4rem;
        }

        span {
          display: none;
          color: white;
          font-weight: normal;
          white-space: nowrap;
          margin: 0 0 0 1.2rem;
          line-height: 2.4rem;

          @include media(">=tablet") {
              display: block;
          }
        }
      }

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: $primary;

        img {
          width: 3.6rem;
          height: auto;

          @include media(">=tablet") {
              width: 5.2rem;
            margin: 0 0 0.8rem 0;
          }
        }

        span {
          color: white;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 600;

          @include media(">=tablet") {
            font-size: 2.4rem;
            line-height: 3.2rem;
          }
        }
      }
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 4.6rem;
    background: url("/assets/images/extension.svg") no-repeat;
    background-size: 100% 4.6rem;

    @include media(">=tablet") {
      height: 10rem;
      background-size: 100% 10rem;
    }

    @include media(">=desktop-lg") {
      height: 12rem;
      background-size: 100% 12rem;
    }
  }
}

.container {
  box-sizing: border-box;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 1.6rem;

  @include media(">=tablet") {
    padding: 0 3.2rem;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 1.2rem 0 0;

  &:hover {
    .checkmark {
      border-color: $primary;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background: $primary;
        border-color: $primary;

        &:after {
          content: "\f00c";
          font-family: "fontawesome";
          color: white;
          font-size: 1rem;
          line-height: 1rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .checkmark {
    display: block;
    position: relative;
    height: 1.6rem;
    width: 1.6rem;
    border: 0.1rem solid $gray300;
    border-radius: 0.4rem;
    transition: border-color 0.3s ease-in-out;
  }
}

.form-wrapper.form-succeed{
  .heading{
    .navigation{
      @include media("<tablet"){
        padding: 2rem 0 2rem 0;
      }

    }
  }
  .form-box.success-message{
    @include media("<tablet"){
      margin: 0 auto 0 auto;
    }
  }
}

.pill {
  border-radius: 2.4rem;
  padding: 0.2rem 1.2rem;
  background: $gray100;
  color: $gray800;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  width: max-content;

  &.green {
    background: $green100;
    color: $green800;
  }

  &.orange {
    background: $orange100;
    color: $orange800;
  }

  &.red {
    background: $red100;
    color: $red800;
  }

  &.blue {
    background: $blue100;
    color: $blue800;
  }
}
.animal-comparison {
  .animal-list {
    &.animal-selected {
      li:not(.selected) {
        opacity: 60%;
      }
    }
  }
}

mat-tab-group {
  flex: 1;

  .mat-tab-header {
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.06);
    z-index: 2;

    @include media(">=desktop") {
      box-shadow: 0 0.4rem 0.6rem -0.1rem rgba(0, 0, 0, 0.1), 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.06);
      // flex:1.1;
    }

    .mat-tab-label-container {
      box-sizing: border-box;
      max-width: 128rem;
      margin: 0 auto;
      padding: 0.8rem 0;

      @include media(">=tablet") {
        padding: 1.6rem 3.2rem;
      }

      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            height: 3.6rem;
            padding: 0.8rem 1.2rem;

            border-radius: 0.6rem;
            min-width: auto;
            opacity: 1;
            &:not(:last-child) {
              margin: 0 0.4rem 0 0;
            }

            &.mat-tab-label-active {
              background: $primary;
              color: white;
            }
          }
        }

        .mat-ink-bar {
          display: none;
        }
      }
    }
  }
  .mat-tab-body-wrapper {
    // padding-bottom: 1rem;
    flex: 1;
    @include media(">=desktop") {
      flex:3;
    }

    .mat-tab-body {
      overflow: unset;
      width: 100%;

      .mat-tab-body-content {
        overflow: unset;
      }
    }
  }
}


app-account-settings{
  mat-tab-group{
    // @include media(">=desktop"){
    //   flex-direction: row;
    // }
    .mat-tab-header{
      box-shadow: none;
      .mat-tab-label-container{
        padding: 0.8rem;
          @include media(">=desktop"){
            padding: 0 3.6rem 0 0;
          }
        .mat-tab-list {
          .mat-tab-labels {
            flex-direction: column;
            .mat-tab-label{
              // padding: 1rem 2rem;
              padding: 1rem;
              height: 4rem;
              margin: 0;
              justify-content: flex-start;
              .mat-tab-label-content{
                i{
                  font-size: 1.8rem;
                  margin-right:1.5rem;
                  color: $gray400;
                }
                span{
                  font-size: 1.4rem;
                }
              }
              &.mat-tab-label-active{
                background: white;
                color: $primary;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
                span, i{
                  color: $primary
                }
              }
            }
          }
        }
      }
    }
  }
}

.events-grid {
  .mat-tab-header {
    box-shadow: none;
    .mat-tab-label-container {
      padding: 0.8rem 1.6rem;

      @include media(">=tablet") {
        padding: 1.6rem 3.2rem;
      }

      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            height: 3.2rem;
            border-radius: 2rem;
            padding: 0.4rem 1.6rem;
            background: $gray100;
          }
        }
      }
    }
  }
  .mat-tab-group .mat-tab-header .mat-tab-label-container {
    width:100%;
    .mat-tab-labels {
      width: 100%;
    }
  }
  .mat-tab-body-wrapper {
    padding: 2.4rem 1.6rem;

    @include media(">=tablet") {
      padding: 2.4rem 3.2rem;
    }

  }
  .mat-tab-body-content {
    @include media(">=tablet") {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem 1.4rem;
    }

    @include media(">=desktop") {
      grid-template-columns: 1fr 1fr 1fr;
    }

  }

}

.box-shadow-lg {
  box-shadow: 0 1rem 1.5rem -0.3rem rgba(0, 0, 0, 0.1), 0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.05);
}

.box-shadow-xl {
  box-shadow: 0 2rem 2.5rem -0.5rem rgba(0, 0, 0, 0.1), 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.04);
}

.box-shadow-sm {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
}

.box-shadow-md { //shadow-base
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.06);
}


.search-field {
  position: relative;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);

  &:before {
    content: "\f002";
    font-family: "Font Awesome 6 Pro", sans-serif;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: $gray400;
  }

  input {
    width: 100%;
    padding: 0 1.2rem 0 3.2rem;
    box-sizing: border-box;
    border: 0.1rem solid #D1D5DB;
    border-radius: 0.6rem;
    height: 3.8rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);

    &:focus {
      outline: none;
    }
  }
}

.content-spinner {
  position: relative;
  min-height: 14rem;
  .spinner-container {
    background-color: rgba(white, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

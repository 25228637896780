.btn {
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.2rem;
  border-radius: 0.6rem;
  padding: 0 1.2rem;
  white-space: nowrap;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  transition: background 0.2s ease-in-out;
  font-weight: 500;

  @include media(">=tablet") {
    padding: 0 2.4rem;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem $blue700;
  }

  &.primary {
    background: $primary;
    color: white;

    &:hover {
      background: $blue700;
    }
  }

  &.primary-light {
    background: $blue100;
    color: $blue700;
  }


  &.secondary {
    background: white;
    border: 0.1rem solid $gray300;
    color: $gray700;

    &.back::before{
      content: "\f177";
      display: block;
      font-family: "Font Awesome 5 Pro", sans-serif;
      margin-right: 1rem;
      font-weight: 700;
    }

    &:hover {
      background: $gray50;
    }
  }

  &.danger {
    background: $red600;
    color: white;

    &:hover {
      background: $red700;
    }
  }

  &.danger-light {
    background: $red50;
    color: $red700;
    border: 1px solid $red700;
    &:hover {
      background: $red100;
    }
  }

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  &.prefix{
    i {
      margin: 0 1rem 0 0;
      @include media(">=tablet") {
        margin: 0 1.6rem 0 0;
      }
    }
  }
}

.icon-btn {
  border: none;
  border-radius: 50%;
  width: 5.8rem;
  height: 5.8rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  transition: background 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;


  &.primary {
    background: $primary;
    color: white;

    &:hover {
      background: $blue700;
    }
  }

  &.primary-light {
    background: $blue500;
    color:white;
    &:hover{
      background: lighten($blue500,5%);
    }
  }

  &.primary-light {
    background: $blue500;
    color:white;
    &:hover{
      background: lighten($blue500,5%);
    }
  }

  &.primary-light {
    background: $blue500;
    color:white;
    &:hover{
      background: lighten($blue500,5%);
    }
  }

  &.secondary {
    background: white;
    color: $primary;

    &:hover {
      background: $gray50;
    }
  }

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
}

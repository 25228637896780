mat-dialog-container {
  &.mat-dialog-container {
    border-radius: 1.2rem;
  }
}


mat-bottom-sheet-container {
  &.mat-bottom-sheet-container,
  &.mat-bottom-sheet-container-medium,
  &.mat-bottom-sheet-container-large {
    border-radius: 1.2rem 1.2rem 0 0;
  }
}


.animal-details-modal{
  width:100%;
  // max-width: 55.5rem;
  mat-dialog-container{
    // padding:0;
    border-radius: 0;
    overflow:hidden;
    padding: 5rem 0 5rem 0;
    max-height: 100vh;
    background: transparent;
    box-shadow: none;
    .animal-details-wrapper{
      // border-radius: 12px 12px 0 0;
      box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }
}

.scrollable-container {
  &.mat-bottom-sheet-container {
    overflow: hidden;
  }
  .mat-dialog-container {
    overflow: hidden;
  }

}

.cancel-registration-modal, .change-email-modal, .animal-share-modal{
  mat-dialog-container{
    padding: 3.2rem;
  }

}

mat-bottom-sheet-container.cancel-registration-modal, mat-bottom-sheet-container.change-email-modal, mat-bottom-sheet-container.animal-share-modal{
    padding: 5.1rem 1.6rem 5.8rem;
    p{
      padding:0 2rem;
    }
}

mat-bottom-sheet-container.testkit-animals-modal{
  max-width: 56rem;
  width: 56rem;
  padding: 2rem 1.6rem 3.2rem;
}

.testkit-animals-modal{
  // overflow: auto;
  max-height:95vh;
  max-width: 56rem;
  width: 100%;
}

.download-results {
  mat-dialog-container {
    @include media("<=mobile"){
      position: absolute;
      left: 0;
    }
  }
  &.download-breed-results {
    @include media(">640px"){
      width: 400px;
    }
    h1 {
      text-align: center;
    }
  }
}

.health-info-modal, .color-info-modal{
  mat-dialog-container{
    padding: 2.4rem;
    @include media(">=tablet"){
      padding:3.2rem;
    }
  }
}

mat-bottom-sheet-container.health-info-modal, mat-bottom-sheet-container.color-info-modal{
  padding: 2.4rem 2.4rem 3.5rem;
}

.submission-details-modal {
  // background-color: white;
  width: 100%;
  overflow: auto;
}

/* Color Scheme */

//Primary
$primary: #0284C7;
$success: #10B981;
$warning: #F59E0B;
$danger: #DC2626;

//Shades
$gray50: #F9FAFB;
$gray100: #F3F4F6;
$gray200: #E5E7EB;
$gray300: #D1D5DB;
$gray400: #9CA3AF;
$gray500: #6B7280;
$gray600: #4B5563;
$gray700: #374151;
$gray800: #1F2937;
$gray900: #111827;

$red50: #FEF2F2;
$red100: #FEE2E2;
$red200: #FECACA;
$red300: #FCA5A5;
$red400: #F87171;
$red500: #EF4444;
$red600: #DC2626;
$red700: #B91C1C;
$red800: #991B1B;
$red900: #7F1D1D;

$orange50: #FFF7ED;
$orange100: #FFEDD5;
$orange200: #FED7AA;
$orange300: #FDBA74;
$orange400: #FB923C;
$orange500: #F97316;
$orange600: #EA580C;
$orange700: #C2410C;
$orange800: #9A3412;
$orange900: #7C2D12;

$yellow50: #FFFBEB;
$yellow100: #FEF3C7;
$yellow200: #FDE68A;
$yellow300: #FCD34D;
$yellow400: #FBBF24;
$yellow500: #F59E0B;
$yellow600: #D97706;
$yellow700: #B45309;
$yellow800: #92400E;
$yellow900: #78350F;

$green50: #ECFDF5;
$green100: #D1FAE5;
$green200: #A7F3D0;
$green300: #6EE7B7;
$green400: #34D399;
$green500: #10B981;
$green600: #059669;
$green700: #047857;
$green800: #065F46;
$green900: #064E3B;

$blue50: #F0F9FF;
$blue100: #E0F2FE;
$blue200: #BAE6FD;
$blue300: #7DD3FC;
$blue400: #38BDF8;
$blue500: #0EA5E9;
$blue600: #0284C7;
$blue700: #0369A1;
$blue800: #075985;
$blue900: #0C4A6E;

$indigo100: #E0E7FF;

$terrier: $blue600;
$companion: $success;
$guard: $gray700;
$herding: $danger;


$sheepdogs: #0284C7;
$pinscher: #10B981;
$terriers:#374151;
$dachshunds:#DC2626;
$spitz: #F97316;
$scenthounds: #7C3AED;
$pointing:#14B8A6;
$retrievers:#8B5CF6;
$toy: #0284C7;
$sighthounds: #E11D48;
$noFci: #C2410C;

.mat-table {
  width: 100%;

  thead {
    tr {
      background: white;
      height: 4rem;

      th {
        vertical-align: middle;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.6rem;
        padding: 0 1.2rem;
        white-space: nowrap;
        color: black;

        &.mat-sort-header-disabled .mat-sort-header-container {
          cursor: pointer;
        }
      }
    }
  }

  tr {
    cursor: pointer;
    background: #F9FAFB;
    border-bottom: 2px solid white;

    td {
      height: 5.2rem;
      vertical-align: middle;
      border: none;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $gray500;
      padding: 0 1.2rem;
      &.td-bold {
        font-weight: 700;
        font-size: 1.6rem;
        color: black;
        i {
          font-size: 2.4rem;
          font-weight: 400;
          line-height: 2.3rem;
          color: $primary;
        }
      }
      &.td-flex {
        display: flex;
        gap: 0.8rem;
        align-items: center;
      }
      &.td-state {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .status-info {
          background-color: $yellow100;
          color: $yellow800;
          padding: 0.5rem 2rem;
          border-radius: 2rem;
          display: flex;
          flex-shrink: 0;
          font-size: 1.4rem;

          &.approved {
            background-color: $green300;
            color: $green800;
          }

          &.rejected {
            background-color: $red100;
            color: $red800;
          }
        }
      }
    }
  }
}

.grid-wrapper{
  .columns-10 {
    td {
      width: 10%;
    }
  }

  *[class*=mat-column-]:last-of-type {
    .actions-wrapper {
      padding-right:2.4rem;
      display:flex;
      justify-content: flex-end;
    }
  }
}

.dashboard-animals-table {
  width: 100%;
  margin-top: 4.6rem;
  box-shadow: none;
  thead tr, tbody tr {
    border-bottom: 1px solid $gray300;
  }
  tbody tr.more-details-row, tbody tr:nth-last-of-type(2) {
    border-bottom: none;
  }
  thead tr, tr {
    background-color: $gray100;
  }
  tr {
   &.expanded-row {
     background: white;
     border-bottom: 1px solid transparent;
   }
  }
  tbody>tr {
   &:not(.more-details-row):not(.expanded-row):hover {
     background-color: $gray50;
   }
   td {
     padding: 0.8rem 1.2rem;
     font-weight: 400;
     font-size: 1.4rem;
     line-height: 2.4rem;
     color: black;
     &.minimal-width {
       width: 0;
     }
     &.width-20 {
       width: 20%;
     }
   }
  }
  thead {
   tr {
    th {
      color: $gray600;
      font-weight: 600;
      .mat-sort-header-container, .mat-sort-header-content {
        font-size: inherit;
      }
    }
   }
  }

  .documents-info {
    width: 100%;
    tr {
      border-bottom: none;
      background-color: white;
    }
  }

  tr.more-details-row {
    height: 0;
    &>td {
      height: auto;
      padding-top: 0;
      padding-bottom: 0;

      .element-detail-wrapper {
        .element-detail {
          padding: 1.4rem 0;
          .documents-info {
            td {
              font-size: 1.6rem;
            }
            &>tr:not(.no-data-tr):hover {
              background-color: $gray50;
            }
          }
        }
      }
    }
  }
}

@import './variables';

.dropdown {
  position: relative;
  padding: 0.9rem 1.3rem;
  font-size: 1.6rem;
  border-radius: 0.6rem;
  border: 0.1rem solid #D1D5DB;
  line-height:2.2rem;
  background: white;
  color: black;
  outline: none;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  &.disabled {
    background: $gray50;
    cursor: not-allowed;
  }


  &:after {
    display: block;
    font-family: "Font Awesome 5 Pro", sans-serif;
    content: "\f078";
    color: black;
    font-size: 1.2rem;
    position: absolute;
    right:1.3rem;
    top: 50%;
    transform: translateY(-50%);
  }


  .value {
    color: $gray300;
    &.has-value {
      color:black;
    }
  }

  &.open {
    &:after {
      content: "\f077";
    }

    .options {
      display: block;
    }
  }

  .options {
    display: none;
    width: calc(100% + 0.2rem);
    position: absolute;
    left: -0.1rem;
    top: 100%;
    z-index: 999;
    background:white;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0.6rem;
    li {
      cursor: pointer;
      padding: 1.2rem 1.6rem;
      transition: background 0.3s ease-in-out;

      &:hover {
        background: darken($color: $gray50, $amount: 5%);

      }
    }
  }
}

.report-settings-wrapper {
  .dropdown {
    width: 100%;
  }
}


.form-control {
  display: flex;
  flex-direction: column;
  margin: 0 0 1.6rem 0;

  &.has-error {
    .input-wrapper {
      position: relative;

      &:after {
        content: "\f06a";
        font-family: "FontAwesome", sans-serif;
        color: $red500;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.64rem;
      }

      input {
        color: $red900;
        border: 0.1rem solid $red300;

        &:focus {
          border: 0.1rem solid $red500;
          outline: 0.1rem solid $red500;
        }
      }

      .dropdown{
        border: 0.1rem solid $red500;
      }
    }

    .error-message {
      display: block;
    }
  }

  label {
    color: $gray700;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
    margin: 0 0 0.4rem 0;
  }

  .input-wrapper {
    input {
      color: $gray900;
      width: 100%;
      box-sizing: border-box;
      border: 0.1rem solid $gray300;
      border-radius: 0.6rem;
      height: 4.2rem;
      padding: 0 4.4rem 0 1.2rem;
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);

      &:focus {
        border: 0.1rem solid $blue500;
        outline: 0.1rem solid $blue500;
      }

      &::placeholder {
        color: $gray300;
      }

      &:-webkit-autofill,
      &:-webkit-autofill::first-line,
      &:-webkit-autofill::placeholder,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 3rem white inset !important;
        font-size: 1.6rem !important;
        line-height: 2.4rem !important;
      }
    }

    &.dropdown-input-wrapper,  &.autocomplete-input-wrapper{
      &:after{
        right:3.6rem;
      }
    }
  }

  .error-message {
    display: none;
    margin: 0.8rem 0 0 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: $red600;
  }
}

.form-box {
  // max-width: 72rem;
  max-width: 45.6rem;
  margin: 3rem auto 0 auto;
  background: white;

  @include media(">=tablet") {
    position: relative;
    margin: -12rem auto 0 auto;
    padding: 3.2rem;
    border-radius: 1.2rem;
    box-shadow: 0 1rem 1.5rem -0.3rem rgba(0, 0, 0, 0.1), 0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.05);
  }

  @include media(">=desktop") {
    margin: -8rem auto 0 auto;
  }

  @include media(">=desktop-lg") {
    margin: -10rem auto 0 auto;
  }
}
.success-message {
  h1, p {
    text-align: center;
  }
  h1{
    margin:0 0 0.8rem 0;
    @include media(">=tablet"){
      margin:0 0 1.6rem 0;
    }
  }
  p{
    margin: 0 0 2.4rem 0;
  }

  .checkmark-icon {
    margin: -4rem auto 2.5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.2rem;
    height: 11.2rem;
    border-radius: 50%;
    background: white;
    box-shadow: 0 1rem 1.5rem -0.3rem rgba(0, 0, 0, 0.1), 0 0.4rem 6rem -0.2rem rgba(0, 0, 0, 0.05);
    position:relative;
    @include media(">=tablet"){
      margin:0 auto 1.6rem auto;
      box-shadow: initial;
      border-radius: initial;
      width:initial;
      height:initial;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.4rem;
      height: 6.4rem;
      border-radius: 50%;
      border: 0.7rem solid $green500;
      box-sizing: border-box;

      &:before {
        font-size: 4.9rem;
        line-height: 4.9rem;
        color: $green500;
        @include media('>=tablet'){
          font-size:6.4rem;
          line-height: 6.4rem;
        }
      }
    }
  }
  .form-actions {
    display: flex;
    flex-wrap: wrap;
    margin: 2.4rem 0 0 0;

    button {
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.form-actions {
  display: flex;
  flex-wrap: wrap;
  margin: 2.4rem 0 0 0;

  button {
    margin: 0 0 1.6rem 0;
    width: 100%;
    font-weight:500;

    &:last-child {
      margin: 0;
    }

    @include media(">=tablet") {
      flex: 1;
      width: auto;
      margin: 0 1.6rem 0 0;
    }
  }
}

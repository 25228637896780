@import './variables';

// Mat datepicker
.cust-datepicker {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.4rem;
  &.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        border-radius: 0.4rem;
        align-items:center;

        .mat-form-field-infix {
          border: 0;
          padding:0;

          .mat-input-element {
            margin-top:0;
            padding: 0 0.8rem;
            font-family: inherit;
            &:-webkit-autofill {
              -webkit-text-fill-color: $gray500 !important;
            }
          }

          .mat-form-field-label-wrapper {
            top: 50%;
            transform: translateY(-50%);
            padding-top:0;
            display:flex;
            align-items: center;

            .mat-form-field-label {
              box-sizing: border-box;
              margin: 0;
              position: initial;
              color: $gray300;
              line-height: 1.6rem;
              font-family: inherit;
              padding: 0 0.8rem;
              span:first-child{
                color: $gray300;
              }
              .mat-placeholder-required{
                display:none;
              }
            }
          }
        }

        .mat-form-field-suffix {
          position: absolute;
          width:100%;
          height:100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .mat-datepicker-toggle{
            width:100%;
            height:100%;
            button{
              display:none;
            }
          }
          .mat-focus-indicator {
            width: 2.4rem;
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 2.5rem;
      }
    }
  }

  &.mat-form-field-should-float {
    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                color: $gray300;
              }
            }
          }
        }
      }
    }
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            padding-top: 3rem;
            visibility: hidden;
          }
        }
      }
    }
  }

}

.mat-calendar-content{
  .mat-calendar-table-header{
    abbr{
      text-decoration: none;
      cursor: none;
    }
  }
  .mat-calendar-body-cell{
    .mat-calendar-body-selected{
      background: $primary;
    }
  }
}


// Mat autocomplete
.cust-autocomplete {
  width:100%;
  &.mat-form-field-should-float {
    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                color: $primary;
              }
            }
          }
        }
      }
    }
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            padding-top: 2.9rem;
          }
        }
      }
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    border-radius: 0.4rem;

    .mat-form-field-flex {
      border-radius: 0.4rem;

      .mat-form-field-infix {
        border: 0;
        padding:0;
        &::after {
          display: block;
          font-family: "Font Awesome 5 Pro", sans-serif;
          content: "\f078";
          color: black;
          font-size: 1.2rem;
          position: absolute;
          right:1.3rem;
          top: 50%;
          transform: translateY(-50%);
        }

        &.open{
          &:after {
            content: "\f077";
          }
        }

        .mat-input-element {
          color:black;
          background:initial;
          font-family: inherit;
          position: relative;
          z-index: 999;
        }

        .mat-select-placeholder {
          color: $gray300;
          font-family: inherit;
        }

        .mat-form-field-label-wrapper {
            top: 50%;
            transform: translateY(-50%);
            padding-top:0;
            display:flex;
            align-items: center;

          .mat-form-field-label {
            line-height: 1.6rem;
            font-family: inherit;
            padding: 0 0.8rem;
            box-sizing: border-box;
            margin: 0;
            position: initial;
            mat-label{
              color: $gray300;
            }
            .mat-placeholder-required{
              display:none;
            }
          }
        }
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-autocomplete-panel{
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05) !important;
  border-radius: 0.6rem;
  overflow:hidden !important;
  max-height:initial !important;
  .mat-option{
    line-height: initial !important;
  }
  .mat-option:hover{
    background:rgba(0, 0, 0, 0.04) !important;
  }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled), .mat-option.mat-active{
    background:transparent;
  }
}


// MAT SLIDE TOGGLE
.mat-slide-toggle{
  &.mat-checked{
    .mat-slide-toggle-label{
      .mat-slide-toggle-thumb{
        background:white;
      }
      .mat-slide-toggle-bar{
        background:$primary;
      }
      .mat-slide-toggle-bar{
        .mat-slide-toggle-thumb-container{
          transform: translate3d(105%, -50%, 0);
        }
      }

    }
  }
  .mat-slide-toggle-label{
    margin-bottom:0;
    .mat-slide-toggle-bar{
      background:$gray200;
      height:2.4rem;
      width:4.4rem;
      border-radius:1.2rem;
      .mat-slide-toggle-thumb-container{
        transform: translate3d(0, -50%, 0);
        top:50%;
        // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
      }

    }
  }
  // &.mat-container{
  //   top:0;
  //   left:0;
  // }
  // .mat-slide-toggle-thumb-container{
  //   transform: translate3d(0, -50%, 0);
  //   top:50%;
  // }
  // .mat-slide-toggle-bar {
  //   height:2.4rem;
  //   width:4.4rem;
  // }
}

.animal-profile{
  mat-tab-group{
    position: relative;
    mat-tab-header{
      position: sticky;
      top:0;
      background: white;
    }
  }
}
.mat-paginator {
  background-color: inherit;

  &.paginator-centered {
    .mat-paginator-container {
      justify-content: center;
      .mat-icon-button {
        color: $primary;
      }
      .mat-icon-button.mat-button-disabled {
        color: $gray300;
      }
      .mat-icon-button {
        width: 60px;
        .mat-paginator-icon {
          width: 42px;
        }
      }
      .mat-paginator-range-label {
        display: none;
        @include media(">=tablet") {
          display: block;
        }
      }
      .mat-paginator-range-label, .mat-paginator-navigation-next, .mat-paginator-navigation-last {
        order: 1;
      }
    }
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $primary;
}

.mat-paginator {
  background-color: inherit;

  &.paginator-centered {
    .mat-paginator-container {
      justify-content: center;
      .mat-icon-button {
        color: $primary;
      }
      .mat-icon-button.mat-button-disabled {
        color: $gray300;
      }
      .mat-icon-button {
        width: 60px;
        .mat-paginator-icon {
          width: 42px;
        }
      }
      .mat-paginator-range-label {
        display: none;
        @include media(">=tablet") {
          display: block;
        }
      }
      .mat-paginator-range-label, .mat-paginator-navigation-next, .mat-paginator-navigation-last {
        order: 1;
      }
    }
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $primary;
}



body {
  * {
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
  }

  h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    @include media(">=desktop"){
      font-weight: 700;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 600;
  }

  h3 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: 700;
  }

  h6 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 700;
  }

  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    @include media(">=tablet"){
      font-size: 1.6rem;
    }
  }

  a {
    color: $primary;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .lh {
    &-20 {
      line-height: 2rem;
    }
    &-24 {
      line-height: 2.4rem;
    }
    &-28 {
      line-height: 2.8rem;
    }
  }
}
